import { useEffect } from "react";
import AppWrap from "../wrapper/AppWrap";
import MotionWrap from "../wrapper/MotionWrap";
import Email from "../assets/email.png";
import { motion } from "framer-motion";
import WhatsAppIcon from "../assets/WhatsApp_icon.png";
import Instragram from "../assets/instagram.png";
import Agenda from "../assets/agenda.png";

const Footer = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="w-full flex justify-center items-center flex-col z-40" id="Contact">
            <h2 className="head-text">Contáctanos</h2>
            <div className="w-3/5 max-lg:w-full flex justify-evenly items-center flex-wrap max-lg:m-0 max-lg:p-0 m-4">
                <a href="mailto:jmfreelance73@gmail.com" className="min-w-72 p-text font-medium max-lg:w-full flex flex-row justify-start items-center m-4 p-4 rounded-xl cursor-pointer bg-[#FEF4F5] transition-all duration-300 ease-in-out hover:bg-[shadow-custom-light]">
                    <img src={Email} alt="email" className="w-10 h-10 mr-4" />
                    jmfreelance73@gmail.com
                </a>
                <a href="https://www.instagram.com/solutionsjm2024/" target="_blank" rel="noreferrer" className="min-w-72 max-lg:w-full p-text font-medium flex flex-row justify-start items-center m-4 p-4 pl-0 rounded-xl cursor-pointer bg-[#F2F7FB] transition-all duration-300 ease-in-out hover:bg-[shadow-custom-light]">
                    <img src={Instragram} alt="Instagram" className="w-auto h-10 m-1" />
                    Visita nuestro Instagram
                </a>
            </div>
            <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="transform w-3/5 max-lg:w-full flex max-lg:m-0 max-lg:p-0 justify-evenly items-center flex-wrap m-8"
            >
                <a href="https://api.whatsapp.com/send?phone=+573004595660&text=Hola!%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20su%20servicio." target="_blank" rel="noreferrer" className="min-w-72 max-lg:w-full p-text font-medium flex flex-row justify-start items-center m-4 p-4 rounded-xl cursor-pointer bg-[#F2F7FB] transition-all duration-300 ease-in-out hover:bg-[shadow-custom-light]">
                    <img src={WhatsAppIcon} alt="WhatsApp" className="w-10 h-10 mr-4" />
                    Contáctanos al WhatsApp
                </a>
                <a href="https://calendly.com/j-msolutions/agenda-tu-cita" target="_blank" rel="noreferrer" className="min-w-72 max-lg:w-full p-text font-medium flex flex-row justify-start items-center m-4 p-4 rounded-xl cursor-pointer bg-[#F2F7FB] transition-all duration-300 ease-in-out hover:bg-[shadow-custom-light]">
                    <img src={Agenda} alt="Agenda" className="w-10 h-10 mr-4" />
                    Agenda una reunión con nosotros!
                </a>
            </motion.div>
            <div className="copyright">
                <p className="p-text">@2024 J&MSolutions</p>
                <p className="p-text">Todos los derechos reservados</p>
            </div>
        </div>
    );
};

export default AppWrap(MotionWrap(Footer, "app__footer"), "contact", "app__primarybg");
