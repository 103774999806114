import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { urlFor, client } from "../client";
import AppWrap from "../wrapper/AppWrap";
import MotionWrap from "../wrapper/MotionWrap";


const SlideUp = (delay) => {
  return {
    hidden: {
      y: "100%",
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: delay,
      },
    },
  };
};

const About = () => {

  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';
    client.fetch(query).then((data) => setAbouts(data));
  }, []);

  return (
    <div className="z-40" id="About">
      <h2 className="head-text">
        Sabemos que
        <span> un buen desarrollo </span>
        significa
        <span> un buen negocio.</span>
      </h2>
      <div className="flex justify-center items-start flex-wrap pt-8">
        {abouts.map((about, index) => (
          <motion.div
            variants={SlideUp(index * 0.4)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2, type: 'tween' }}
            className="w-64 flex justify-center items-center flex-col m-8"
            key={about.title + index}
          >
            <img
              src={urlFor(about.imgUrl)}
              alt={about.title}
              className="w-full h-60 rounded-2xl object-cover"
            />
            <p className="text-2xl font-semibold mt-5">{about.title}</p>
            <p className="text-base text-justify font-light mt-3">{about.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);