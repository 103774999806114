import { motion } from "framer-motion";
import circle from '../assets/circle.svg';
import javascript from '../assets/javascript.png';
import motionimg from '../assets/motion.svg';
import sass from '../assets/logoclaro.png';
import AppWrap from "../wrapper/AppWrap";
import { ReactTyped } from "react-typed";
import { useEffect, useState } from "react";
import { client, urlFor } from "../client";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

const Header = () => {
  const [headerImg, setheaderImg] = useState([]);

  useEffect(() => {
    const query = '*[_type == "hero"]';

    client.fetch(query).then((data) => {
      setheaderImg(data);
    });
  }, []);

  return (
    <div
      className="app__flex relative pb-0 mb-0 bg-cover bg-repeat bg-center flex-1 w-full h-full flex-row p-16 pt-28 max-lg:p-0 max-lg:pt-16 max-lg:flex-col"
      id="Home"
    >
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="flex flex-col justify-start items-start h-full max-lg:w-full max-lg:mr-0"
        style={{ flex: '0.65' }}
      >
        <div className="w-full flex justify-end items-end flex-col max-lg:justify-start max-lg:items-start">
          <div
            className="app__flex flex-row p-3 border bg-[#FFFFFF] rounded-2xl w-auto"
            style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }}
          >
            <span className="text-4xl max-lg:text-2xl">👋</span>
            <div className="ml-5">
              <ReactTyped strings={["Hola! Somos J&amp;MSolutions"]} typeSpeed={40} showCursor={false} />
              <h1 className="text-2xl font-bold">
                <ReactTyped
                  strings={["Desarrolo Web", "Diseño Web", "Animaciones Web", "MERN Stack", "Diseño Gráfico", "Marketing"]}
                  typeSpeed={40}
                  backSpeed={80}
                  loop
                />
              </h1>
            </div>
          </div>

          {/* Tarjeta con los nombres */}
          { /*} <div
            className="app__flex flex-col mt-1 p-3 border bg-[#FFFFFF] rounded-2xl w-auto"
            style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }}
          >
            <ReactTyped strings={["Moises Agudelo"]} typeSpeed={40} showCursor={false} />

            <ReactTyped strings={["Juan Estaban Salazar"]} typeSpeed={40} showCursor={false} />
          </div> */}
        </div>
      </motion.div >
      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="flex-1 h-full flex justify-end items-end relative max-lg:pt-4"
      >
        {headerImg.length > 0 ? (
          <img src={urlFor(headerImg[0]?.imgUrl)} alt="profile_bg" className="w-full object-contain z-10" />
        ) : (
          <p>Cargando...</p>
        )}
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="absolute left-0 right-0 bottom-0 z-0 w-full h-5/6"
          src={circle}
          alt="circle"
        />
      </motion.div>
      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="flex flex-col max-lg:pt-4 justify-evenly items-start h-full ml-3 max-lg:w-full max-lg:flex-row max-lg:flex-wrap max-lg:ml-0"
        style={{ flex: '0.75' }}
      >
        {[motionimg, sass, javascript].map((circle, index) => {
          const sizeClasses = index === 0
            ? 'w-28 h-28'
            : index === 1
              ? 'w-32 h-32 m-4'
              : 'w-24 h-24';

          return (
            <div
              className={`app__flex ${sizeClasses} rounded-full bg-[#FFFFFF]`}
              key={`circle-${index}`}
              style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }}
            >
              <img src={circle} alt="profile_bg" className="w-[60%] h-[60%]" />
            </div>
          );
        })}

      </motion.div>
    </div >
  )
}

export default AppWrap(Header, 'home');