import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

if (typeof window !== "undefined") {
    const resizeObserverErrDiv = document.createElement('div');
    resizeObserverErrDiv.id = 'webpack-dev-server-client-overlay-div';
    resizeObserverErrDiv.style = 'display: none';
    document.body.appendChild(resizeObserverErrDiv);

    const resizeObserverErrStyle = document.createElement('style');
    resizeObserverErrStyle.innerHTML =
        '#webpack-dev-server-client-overlay { display: none }';
    document.body.appendChild(resizeObserverErrStyle);
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);