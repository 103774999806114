import logo from '../assets/logojm.png';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
import { useState } from 'react';
import bg from '../assets/bgWhite.png'

const Navbar = () => {

    const [toggle, setToggle] = useState(false);

    return (
        <nav className='w-full flex justify-between items-center p-2 bg-[#14213d] backdrop-blur-[4px] border border-white/20 fixed z-50'>
            <div className='flex justify-start items-center'>
                <img src={logo} alt='Logo' className='w-[150px] h-[40px] lg:w-[180px] lg:h-[55px]' />
            </div>
            <ul className='flex-1 flex justify-evenly items-center list-none max-lg:hidden'>
                {['Home', 'About', 'Contact', 'Work', 'Skills'].map((item, index) => (
                    <li key={index} className='cursor-pointer flex flex-col items-center group'>
                        <div className='w-[5px] h-[5px] bg-transparent rounded-full mb-1 transition-all duration-300 ease-in-out group-hover:bg-[#ffffff]' />
                        <a href={`#${item}`} className='text-[#6b7688] flex-col uppercase font-semibold transition-all duration-300 ease-in-out hover:text-[#ffffff]'>
                            {item}
                        </a>
                    </li>
                ))}
            </ul>
            <div className='lg:hidden w-9 h-9 rounded-full relative flex justify-center items-center bg-[#313bac]'>
                <HiMenuAlt4 onClick={() => setToggle(true)} className='w-[70%] h-[70%] text-[#FFFFFF]' />
                {toggle && (
                    <motion.div
                        whileInView={{ x: [300, 0] }}
                        transition={{ duration: 0.85, ease: 'easeOut' }}
                        style={{ backgroundImage: `url(${bg})` }}
                        className="fixed top-0 bottom-0 right-0 p-4 z-50 w-4/5 h-screen flex justify-end items-end flex-col bg-[#FFFFFF] bg-cover bg-repeat shadow-custom-light"
                    >
                        <HiX onClick={() => setToggle(false)} className='w-9 h-9 text-[#313bac] m-1' />
                        <ul className='m-0 p-0 h-full w-full flex justify-start items-start flex-col'>
                            {['Home', 'About', 'Contact', 'Work', 'Skills'].map((item, index) => (
                                <li key={index} className='mx-4 cursor-pointer flex flex-col items-center m-4'>
                                    <a href={`#${item}`} onClick={() => setToggle(false)} className='text-[#6b7688] flex-col uppercase font-semibold transition-all duration-300 ease-in-out hover:text-[#313bac]'>
                                        {item}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </motion.div>
                )}
            </div>
        </nav>
    )
}

export default Navbar