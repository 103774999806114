import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import AppWrap from '../wrapper/AppWrap';
import { urlFor, client } from "../client";
import MotionWrap from '../wrapper/MotionWrap';


export const fadeIn = (direction, type, delay, duration) => ({
    hidden: {
        x: direction === 'left' ? 100 : direction === 'right' ? -100 : 0,
        y: direction === 'up' ? 100 : direction === 'down' ? -100 : 0,
        opacity: 0,
    },
    show: {
        x: 0,
        y: 0,
        opacity: 1,
        transition: {
            type,
            delay,
            duration,
            ease: 'easeOut',
        },
    },
});

const Skills = () => {
    const [skills, setSkills] = useState([]);
    const [peoples, setPeoples] = useState([]);

    useEffect(() => {
        const peoplequery = '*[_type == "peoples"]';
        const skillquery = '*[_type == "skills"]';

        client.fetch(peoplequery).then((data) => {
            setPeoples(data);
        });
        client.fetch(skillquery).then((data) => {
            setSkills(data);
        });
    }, []);

    return (
        <div className='w-full flex flex-col items-center' id='Skills'>
            <h2 className='head-text'><span>Skills</span> & Team</h2>

            {/* Sección de Habilidades */}
            <div className='flex flex-wrap justify-center w-4/5 mt-12 mb-12'>
                <motion.div className='flex-1 flex flex-wrap justify-center items-center'>
                    {skills?.map((skill) => (
                        <motion.div
                            whileInView={{ opacity: [0, 1] }}
                            transition={{ duration: 0.5 }}
                            className='app__flex flex-col text-center m-4 transition-all duration-300 ease-in-out'
                            key={skill.name}
                        >
                            <div className='app__flex w-24 h-24 rounded-full bg-[#FFF] hover:shadow-custom-light' style={{ backgroundColor: skill.bgColor }}>
                                <img src={urlFor(skill.icon)} alt={skill.name} className='w-1/2 h-1/2' />
                            </div>
                            <p className='p-text font-medium mt-2'>{skill.name}</p>
                        </motion.div>
                    ))}
                </motion.div>
            </div>

            {/* Sección de Equipo */}
            <div className='flex flex-wrap justify-center items-center w-full'>
                {peoples && peoples.map((ppl, index) => (
                    <motion.div
                        variants={fadeIn('right', 'spring', index * 0.5, 0.75)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true }}
                        whileHover={{ scale: 1.1 }}
                        className="relative flex flex-col m-6 w-72 h-96 bg-white rounded-lg shadow-lg overflow-hidden"
                        key={ppl.name}
                    >
                        <div className="absolute top-0 left-0 w-1/3 h-full bg-blue-600"></div>
                        <div className="absolute top-0 right-0 w-2/3 h-1/2 bg-white flex justify-center items-center">
                            <img
                                src={urlFor(ppl.imgUrl)}
                                alt={ppl.name}
                                className="object-contain w-40 h-40 bg-gray-200 rounded-full border-4 border-white shadow-lg"
                            />
                        </div>
                        <div className="absolute bottom-0 right-0 w-2/3 h-1/2 bg-gray-200 p-4">
                            <p className="text-black font-bold text-sm">{ppl.name}</p>
                            <p className="text-gray-500 text-xs">{ppl.title}</p>
                        </div>
                        <div className="absolute top-4 left-4 p-2">
                            <span className="bg-white w-12 h-12 flex justify-center items-center rounded-full text-black text-xl font-bold">
                                {index + 1}
                            </span>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    )
}

export default AppWrap(
    MotionWrap(Skills, 'app__skills'),
    'skills', 'app__whitebg'
);
