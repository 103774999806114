import Header from "./container/Header";
import About from "./container/About";
import Navbar from "./components/Navbar";
import Work from "./container/Work";
import Skills from "./container/Skills";
import Testimonial from "./container/Testimonial";
import Footer from "./container/Footer";
import Socialmedia from "./components/Socialmedia";

const App = () => {
  return (
    <div className="app ">
      <Navbar />
      <Socialmedia />
      <section id="header">
        <Header />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="work">
        <Work />
      </section>
      <section id="skills">
        <Skills />
      </section>
      {/* 
      <section id="testimonial">
        <Testimonial />
      </section>
      */}
      <section id="contact">
        <Footer />
      </section>
    </div>
  );
};

export default App;
