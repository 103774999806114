import { useState, useEffect } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { motion } from 'framer-motion';
import AppWrap from '../wrapper/AppWrap';
import { urlFor, client } from "../client";
import MotionWrap from '../wrapper/MotionWrap';

const Work = () => {

  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState('Todos');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWork(data);
    });
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'Todos') {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  return (
    <div className='z-40 flex flex-col justify-center items-center' id='Work'>
      <h2 className="head-text">Nuestros <span>Trabajos</span> !</h2>

      <div className="flex flex-row justify-start items-center flex-wrap m-9">
        {['UI/UX', 'Desarrollo Web', 'Diseño Gráfico', 'MERN Stack', 'Todos'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`p-4 rounded-lg bg-[#FFF] text-[#000] font-extrabold cursor-pointer transition-all duration-300 ease-in-out m-2 hover:bg-[#313bac] hover:text-[#FFF] app__flex ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="flex flex-wrap justify-center items-center"
      >
        {filterWork.map((work, index) => (
          <div className="w-72 flex-col m-8 p-4 bg-[#FFF] text-[#000] cursor-pointer transition-all duration-300 ease-in-out hover:shadow-custom-light app__flex" key={index}>
            <div
              className="w-full group h-[230px] relative app__flex"
            >
              <img src={urlFor(work.imgUrl1)} alt={work.name} className='w-full block group-hover:hidden h-full object-cover rounded-lg' />
              <img src={urlFor(work.imgUrl2)} alt={work.name} className='w-full hidden transition-all duration-300 ease-in-out group-hover:block h-full object-cover rounded-lg' />

              <motion.div
                initial={{ opacity: 0 }} // Opacidad inicial en 0
                whileHover={{ opacity: 1 }} // Durante el hover, la opacidad será 1
                transition={{ duration: 0.25, ease: "easeInOut" }}
                className="absolute w-full h-full rounded-lg top-0 left-0 bottom-0 right-0 bg-[rgba(0,0,0,0.5)] opacity-0 transition-all duration-300 ease-in-out app__flex"
              >
                <a href={work.projectLink} target="_blank" rel="noreferrer">

                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.90] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex w-12 h-12 rounded-full bg-[rgba(0,0,0,0.5)] text-[#FFF] m-4 font-extrabold cursor-pointer transition-all duration-300 ease-in-out"
                  >
                    <AiFillEye className='w-1/2 h-1/2' />
                  </motion.div>
                </a>
                
              </motion.div>
            </div>

            <div className="p-2 w-full relative flex-col app__flex">
              <h4 className="bold-text mt-4 leading-relaxed">{work.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>{work.description}</p>

              <div className="absolute p-3 rounded-xl bg-[#FFF] -top-6 app__flex">
                <p className="p-text">{work.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(Work, 'app__works'),
    'work', 'app__primarybg'
  );