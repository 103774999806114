import { useState, useEffect } from "react";
import AppWrap from '../wrapper/AppWrap';
import MotionWrap from '../wrapper/MotionWrap';
import VideoSlider from "./Swiper.jsx";
import { client } from "../client.js";

const videos = [
    {
        src: "heediUmXHjs",
    },
    {
        src: "Dftj21lTUIA",
    },
    {
        src: "MlmbFnoYMQs",
    }
];

const Testimonial = () => {

    const [brands, setBrands] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleClick = (index) => {
        setCurrentIndex(index);
    }

    useEffect(() => {
        const testiquery = '*[_type == "testimonials"]';
        const brandquery = '*[_type == "brands"]';

        client.fetch(testiquery).then((data) => {
            setTestimonials(data);
        });
        client.fetch(brandquery).then((data) => {
            setBrands(data);
        });
    }, []);

    const test = testimonials[currentIndex];


    return (
        <div className="z-40 flex flex-col justify-center items-center">
            <h2 className='head-text'><span>Nuestros clientes</span> hablan por nosotros.</h2>
            <div className="app__flex">
                <VideoSlider videos={videos} />

            </div>

        </div>
    )
}

export default AppWrap(MotionWrap(Testimonial, 'app__testimonials'), "testimonial", 'app__primarybg');