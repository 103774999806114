import React from 'react';
import Slider from "react-slick";
import { motion } from 'framer-motion';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VideoSlider = ({ videos }) => {
    const settings = {
        dots: false,          // Desactiva los puntos de navegación
        arrows: false,        // Desactiva las flechas de navegación
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,  // Cambia cada 3 segundos
    };

    return (
        <div className="w-[80rem] max-lg:w-[17rem] mx-auto text-center max-w-full overflow-hidden">
            <Slider {...settings}>
                {videos.map((video, index) => (
                    <motion.div
                        key={index}
                        className="relative flex flex-col justify-center items-center m-6 w-full max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden"
                    >
                        <div className="relative w-full h-96">
                            <iframe
                                className="w-full h-full rounded-lg"
                                src={`https://www.youtube.com/embed/${video.src}?autoplay=0&mute=1`}
                                title={`Video ${index + 1}`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            />
                        </div>
                    </motion.div>
                ))}
            </Slider>
        </div>
    );
};

export default VideoSlider
