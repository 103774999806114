import { useEffect, useState } from "react";
import WhatsAppIcon from '../assets/WhatsApp_icon.png';

const Socialmedia = () => {
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const footerEntry = entries[0];
        setIsFooterVisible(footerEntry.isIntersecting);
      },
      { threshold: 0.5 } // Ajusta según cuando quieras que se active la animación
    );

    const footerElement = document.getElementById("contact");
    if (footerElement) {
      observer.observe(footerElement);
    }

    return () => {
      if (footerElement) observer.unobserve(footerElement);
    };
  }, []);

  return (
    <div className="fixed bottom-4 left-4 max-lg:left-2 max-lg:bottom-2 z-50">
      {!isFooterVisible && (
        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-center w-[5%] h-[5%] max-lg:w-[2.1rem] bg-green-500 rounded-full shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer">
            <a
              href="https://api.whatsapp.com/send?phone=+573004595660&text=Hola!%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20su%20servicio."
              className="p-2"
              target="_blank"
              rel="noreferrer"
            >
              <img src={WhatsAppIcon} alt="WhatsApp" className="w-full h-full" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Socialmedia;
